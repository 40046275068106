import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ELoadingStatus, EQuizPage } from '@http/enums';
import { v1 } from '@api/v1';
import { fetchQuizResult } from './quiz-result';
import { IQuiz, IQuizAnswer, IQuestionOption } from '@http/models/quiz';

interface IQuizState {
  [id: number]: IQuiz;
}

const initialState: IQuizState = {};

export const loadQuizInfo = createAsyncThunk(
  'quiz/loadQuizInfo',
  async ({ challengeId, quizId }: { challengeId: number; quizId: number }, { rejectWithValue }) => {
    try {
      const response = await v1.quiz?.info.get(challengeId);
      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }
      return { ...response, challengeId, id: quizId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getQuizQuestions = createAsyncThunk(
  'quiz/getQuizQuestions',
  async (quizId: number, { rejectWithValue }) => {
    try {
      const response = await v1.quiz?.questions.get(quizId);
      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }
      return { id: quizId, ...response };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const startQuiz = createAsyncThunk(
  'quiz/startQuiz',
  async (quizId: number, { rejectWithValue }) => {
    try {
      const response = await v1.quiz?.start.post(quizId);
      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const sendQuizAnswer = createAsyncThunk(
  'quiz/sendQuizAnswer',
  async (
    { quizId, request }: { quizId: number; request: { isFinal: boolean; answers?: IQuizAnswer[] } },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await v1.quiz.submit.post({ quizId, request });

      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }

      if (request.isFinal) {
        const result = await dispatch(fetchQuizResult(response.solutionId)).unwrap();
        dispatch(setCurrentPageType({ id: quizId, currentPageType: EQuizPage.QuizResult }));

        return { ...response, ...request, result };
      }

      // Возвращаем ответ сервера, если запрос не финальный
      return { ...response, ...request };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const finishQuiz = createAsyncThunk(
  'quiz/finishQuiz',
  async (quizId: number, { rejectWithValue }) => {
    try {
      const request = { isFinal: true };
      const response = await v1.quiz.submit.post({ quizId, request });
      if (response.errorCode) {
        return rejectWithValue(response.errorMsg);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const retakeQuiz = createAsyncThunk(
  'quiz/retakeQuiz',
  async (
    { quizId, challengeId }: { quizId: number; challengeId: number },
    { dispatch, rejectWithValue },
  ) => {
    try {
      // Завершить текущий квиз
      await dispatch(finishQuiz(quizId));

      // Сбросить квиз (установить пустое состояние)
      const emptyQuiz: IQuiz = {
        id: quizId,
        challengeId,
      } as IQuiz;
      dispatch(setQuiz({ id: quizId, quiz: emptyQuiz }));

      // Запустить квиз заново
      await dispatch(startQuiz(quizId));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const slice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setCurrentPageType: (
      state,
      action: PayloadAction<{ id: number; currentPageType: EQuizPage }>,
    ) => {
      if (state[action.payload.id]) {
        state[action.payload.id].currentPageType = action.payload.currentPageType;
      }
    },
    setError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      if (state[action.payload.id]) {
        state[action.payload.id].error = action.payload.error;
      }
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ id: number; loadingStatus: ELoadingStatus }>,
    ) => {
      if (state[action.payload.id]) {
        state[action.payload.id].loadingStatus = action.payload.loadingStatus;
      }
    },
    setAnswer: (state, action: PayloadAction<{ id: number; answer: IQuizAnswer }>) => {
      const { id, answer } = action.payload;
      const quiz = state[id];

      if (quiz) {
        if (!quiz.answers) {
          quiz.answers = [];
        }
        const existingAnswerIndex = quiz.answers.findIndex((a) => a.order === answer.order);
        if (existingAnswerIndex !== -1) {
          quiz.answers[existingAnswerIndex] = answer;
        } else {
          quiz.answers.push(answer);
        }
      }
    },
    setAnswers: (state, action: PayloadAction<{ id: number; answers: IQuizAnswer[] }>) => {
      const { id, answers } = action.payload;
      const quiz = state[id];
      if (quiz) {
        quiz.answers = answers;
      }
    },
    changeQuestionIndex: (state, action: PayloadAction<{ id: number; index: number }>) => {
      const { id, index } = action.payload;
      const quiz = state[id];
      if (quiz && quiz.questions) {
        quiz.questions.forEach((question) => {
          question.isCurrent = false;
        });
        if (quiz.questions[index]) {
          quiz.questions[index].isCurrent = true;
        }
        quiz.activeSlide = index;
      }
    },
    setQuiz: (state, action: PayloadAction<{ id: number; quiz: IQuiz }>) => {
      state[action.payload.id] = action.payload.quiz;
    },
    setQuestionOptions: (
      state,
      action: PayloadAction<{
        id: number;
        order: number;
        options?: IQuestionOption[];
        optionsRightGroup?: IQuestionOption[];
      }>,
    ) => {
      const quiz = state[action.payload.id];
      if (quiz) {
        const question = quiz.questions.find((q) => q.order === action.payload.order);

        if (question && action.payload.options) {
          question.options = action.payload.options;
        }

        if (question && action.payload.optionsRightGroup) {
          question.optionsRightGroup = action.payload.optionsRightGroup;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadQuizInfo.pending, (state, action) => {
      state[action.meta.arg.quizId] = {
        ...state[action.meta.arg.quizId],
        loadingStatus: ELoadingStatus.Loading,
      };
    });
    builder.addCase(loadQuizInfo.fulfilled, (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload,
        loadingStatus: ELoadingStatus.Succeeded,
      };
    });
    builder.addCase(loadQuizInfo.rejected, (state, action) => {
      state[action.meta.arg.quizId] = {
        ...state[action.meta.arg.quizId],
        error: action.payload as string,
        loadingStatus: ELoadingStatus.Failed,
      };
    });
    builder.addCase(getQuizQuestions.pending, (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        loadingStatus: ELoadingStatus.Loading,
      };
    });
    builder.addCase(getQuizQuestions.fulfilled, (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload,
        currentPageType: EQuizPage.QuizQuestion,
        loadingStatus: ELoadingStatus.Succeeded,
      };
    });
    builder.addCase(getQuizQuestions.rejected, (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        error: action.payload as string,
        loadingStatus: ELoadingStatus.Failed,
      };
    });
    builder.addCase(startQuiz.pending, (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        loadingStatus: ELoadingStatus.Loading,
      };
    });
    builder.addCase(startQuiz.fulfilled, (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        loadingStatus: ELoadingStatus.Succeeded,
        currentPageType: EQuizPage.QuizQuestion,
        activeSlide: 0,
      };
    });
    builder.addCase(startQuiz.rejected, (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        error: action.payload as string,
        loadingStatus: ELoadingStatus.Failed,
      };
    });
    builder.addCase(sendQuizAnswer.pending, (state, action) => {
      state[action.meta.arg.quizId] = {
        ...state[action.meta.arg.quizId],
        loadingStatus: ELoadingStatus.Loading,
      };
    });
    builder.addCase(sendQuizAnswer.fulfilled, (state, action) => {
      state[action.meta.arg.quizId] = {
        ...state[action.meta.arg.quizId],
        loadingStatus: ELoadingStatus.Succeeded,
        howManyAnsweredQuestions: action.payload.answers?.length || 0,
        solutionId: action.payload.solutionId,
      };
    });
    builder.addCase(sendQuizAnswer.rejected, (state, action) => {
      state[action.meta.arg.quizId] = {
        ...state[action.meta.arg.quizId],
        error: action.payload as string,
        loadingStatus: ELoadingStatus.Failed,
      };
    });
    builder.addCase(finishQuiz.pending, (state, action) => {
      // закрываем квиз
    });
    builder.addCase(finishQuiz.fulfilled, (state, action) => {
      const id = action.meta.arg;
      state[id] = {
        id,
        challengeId: state[id].challengeId,
      } as IQuiz;
    });
    builder.addCase(finishQuiz.rejected, (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        error: action.payload as string,
      };
    });
  },
});

export const {
  setQuiz,
  setCurrentPageType,
  setError,
  setLoadingStatus,
  setAnswer,
  changeQuestionIndex,
} = slice.actions;

const quiz = {
  ...slice.actions,
  selectQuiz: (id: number) => (state: RootState) => state.quiz[id],
  selectLoadingStatus: (id: number) => (state: RootState) => state.quiz[id]?.loadingStatus,
  selectError: (id: number) => (state: RootState) => state.quiz[id]?.error,
  selectCurrentPageType: (id: number) => (state: RootState) => state.quiz[id]?.currentPageType,
  selectQuestions: (id: number) => (state: RootState) => state.quiz[id]?.questions,
  selectAnswer:
    ({ id, order }: { id: number; order: number }) =>
    (state: RootState) =>
      state.quiz[id]?.answers?.find((a) => a.order === order) || { order, answerText: '' },
};

export const quizReducer = slice.reducer;
export default quiz;
