import { isEmpty, unionBy } from 'lodash';
import { IProjectItem } from '@models/project-item';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ELoadingStatus, EMediaType, EProjectSortingOrder } from '@http/enums';
import { RootState } from '../store';
import storage from '@storage/index';
import { v0_1 } from '@api/v0_1';
import { ICommentItem } from '@models/comment-item';
import { v1 } from '@api/v1';

export interface ILoadProjectRequestProps {
  position?: number;
  take?: number;
  direction?: 'next' | 'prev';
  sortingOrder?: EProjectSortingOrder;
}

type ProjectsState = Record<number, IProjectItem>;

const initialState: ProjectsState = {};

export const loadComments = createAsyncThunk(
  'projects/loadComments',
  async (
    { id, loadMore, take = 20 }: { id: number; loadMore?: boolean; take?: number },
    { dispatch, getState },
  ) => {
    console.log('loadComments called with id:', id);
    const state = getState() as RootState;
    const page = loadMore ? (state.projects[id]?.comments?.page || 1) + 1 : 1;

    dispatch(setCommentsError({ id, error: '' }));

    try {
      const response = await v1.project.PROJECT_ID.comment.get({
        projectId: String(id),
        page,
        take,
      });

      if (response.errorCode) {
        throw new Error(response.errorMsg || 'Error loading comments');
      }

      const updatedComments = loadMore
        ? unionBy(state.projects[id]?.comments.items, response.items || [], 'id')
        : response.items || [];

      return { id, comments: updatedComments, total: response.total || 0, page };
    } catch (e) {
      throw e;
    }
  },
);

export const sendComment = createAsyncThunk(
  'projects/sendComment',
  async (
    { id, text, parentId }: { id: number; text: string; parentId: number },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const profile = state.profile.data;
    const random = Math.floor(Math.random() * 1000);

    try {
      const response = await v1.project.PROJECT_ID.comment.post(String(id), {
        text,
        parentId,
      });

      if (response.errorCode) {
        throw new Error(response.errorMsg || 'Error sending comment');
      }

      const newComment: ICommentItem = {
        id: Number(response.id) || random,
        text,
        groupId: Number(response.id) || random,
        rootId: parentId,
        parentId,
        createdDate: new Date().toISOString(),
        state: 'Published',
        stateName: 'New Comment',
        author: {
          id: profile.id || '',
          avatar: profile.avatarUrl || '',
          experience: profile.experience || 0,
          isFollowed: false,
          isGuru: profile.krujok || false,
          level: profile.level || 0,
          name: profile.userName || '',
        },
      };

      dispatch(addComment({ id, comment: newComment }));
      return { id };
    } catch (error) {
      throw error;
    }
  },
);

export const toggleLike = createAsyncThunk(
  'projects/toggleLike',
  async (id: number, { dispatch, getState }) => {
    const state = getState() as RootState;
    const user = state.profile && !isEmpty(state.profile) && state.profile.data;

    if (!user) return;

    try {
      const response = await v0_1.projects.PROJECT_ID.likes.post(id);

      if (response.errorCode) {
        throw new Error(response.errorMsg || 'Error toggling like');
      }

      dispatch(toggleLikeOnProject({ id, user }));
      if (!state.projects[id]?.userLiked) {
        dispatch(storage.userFavoriteProjects.deleteItem(id));
      }
    } catch (error) {
      throw error;
    }
  },
);

export const loadProject = createAsyncThunk(
  'projects/loadProject',
  async (id: number, { dispatch, getState }) => {
    const state = getState() as RootState;
    const videoControlsTimer = state.interactive.videoControlsTimer;

    dispatch(setError({ id, error: '' }));
    dispatch(setLoadingStatus({ id, loading: ELoadingStatus.Loading }));

    try {
      const response = await v1.project.PROJECT_ID.get(id);

      if (response.errorCode) {
        throw new Error(response.errorMsg || 'Error loading project');
      }

      const newProject: IProjectItem = {
        ...response,
        id: response.projectId,
        medias: response.media,
        userLiked: response.likedByCurrentUser,
        author: {
          avatar: response.authorAvatarUrl as string,
          experience: response.authorExperience as number,
          id: response.authorId as string,
          isFollowed: response.isFollowed as boolean,
          isGuru: response.authorIsGuru as boolean,
          level: response.authorLevel as number,
          name: response.authorUserName as string,
        },
        authorPrivacy: response.authorPrivacy,
        presentation: response.presentation,
      };

      if (newProject?.media && newProject?.media[0].type === EMediaType.Image) {
        if (videoControlsTimer !== null) {
          clearTimeout(videoControlsTimer);
        }
        dispatch(storage.interactive.setShowVideoControls(false));
      }

      dispatch(updateItem(newProject));
      dispatch(setLoadingStatus({ id, loading: ELoadingStatus.Succeeded }));
      return newProject;
    } catch (error) {
      dispatch(setError({ id, error: error.message || 'Error' }));
      dispatch(setLoadingStatus({ id, loading: ELoadingStatus.Failed }));
      throw error;
    }
  },
);

const initializeProjectIfNeeded = (state: ProjectsState, id: number): IProjectItem => {
  if (!state[id]) {
    state[id] = {
      id,
      loadingStatus: ELoadingStatus.Idle,
      error: '',
      comments: {
        items: [],
        total: 0,
        page: 1,
        error: '',
      },
      projectId: id,
    } as IProjectItem;
  } else if (!state[id].comments) {
    // Инициализируем comments, если оно отсутствует
    state[id].comments = {
      items: [],
      total: 0,
      page: 1,
      error: '',
    };
  }
  return state[id];
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    addItems: (state, action: PayloadAction<IProjectItem[]>) => {
      action.payload.forEach((project) => {
        state[project.id] = project;
      });
    },
    updateItem: (state, action: PayloadAction<IProjectItem>) => {
      const project = action.payload;
      state[project.id] = state[project.id] ? { ...state[project.id], ...project } : project;
    },
    deleteItem: (state, action: PayloadAction<number>) => {
      delete state[action.payload];
    },
    toggleLikeOnProject: (state, action: PayloadAction<{ id: number; user: any }>) => {
      const project = initializeProjectIfNeeded(state, action.payload.id);
      project.userLiked = !project.userLiked;
      project.likedByCurrentUser = !project.likedByCurrentUser;
      if (project.userLiked) {
        project.likesCount = (project.likesCount || 0) + 1;
        project.recentlyLikedUser = [
          ...(project.recentlyLikedUser || []),
          {
            userAvatar: action.payload.user.avatarUrl,
            userId: action.payload.user.id,
            userName: action.payload.user.userName,
          },
        ];
      } else {
        project.likesCount = (project.likesCount || 0) - 1;
        project.recentlyLikedUser = project.recentlyLikedUser?.filter(
          (user: any) => user.userId !== action.payload.user.id,
        );
      }
    },
    setComments: (state, action: PayloadAction<{ id: number; comments: ICommentItem[] }>) => {
      const project = initializeProjectIfNeeded(state, action.payload.id);
      project.comments = {
        ...project.comments,
        items: action.payload.comments,
      };
    },
    addComment: (state, action: PayloadAction<{ id: number; comment: ICommentItem }>) => {
      const project = initializeProjectIfNeeded(state, action.payload.id);
      project.comments.items.push(action.payload.comment);
    },
    setCommentsMeta: (
      state,
      action: PayloadAction<{ id: number; total: number; page: number; error: string }>,
    ) => {
      const project = initializeProjectIfNeeded(state, action.payload.id);
      project.comments = {
        ...project.comments,
        total: action.payload.total,
        page: action.payload.page,
        error: action.payload.error,
      };
    },
    setLoadingStatus: (state, action: PayloadAction<{ id: number; loading: ELoadingStatus }>) => {
      const project = initializeProjectIfNeeded(state, action.payload.id);
      project.loadingStatus = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ id: number; error: string }>) => {
      const project = initializeProjectIfNeeded(state, action.payload.id);
      project.error = action.payload.error;
      project.loadingStatus = ELoadingStatus.Failed;
    },
    setCommentsError: (state, action: PayloadAction<{ id: number; error: string }>) => {
      const project = initializeProjectIfNeeded(state, action.payload.id);
      project.comments.error = action.payload.error;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadComments.pending, (state, action) => {
        const project = initializeProjectIfNeeded(state, action.meta.arg.id);
        project.loadingStatus = ELoadingStatus.Loading;
        project.comments.error = '';
      })
      .addCase(loadComments.fulfilled, (state, action) => {
        const { id, comments, total, page } = action.payload;
        const project = initializeProjectIfNeeded(state, id);

        project.comments = {
          ...project.comments,
          items: comments,
          total,
          page,
        };

        project.loadingStatus = ELoadingStatus.Succeeded;
      })
      .addCase(loadComments.rejected, (state, action) => {
        const project = initializeProjectIfNeeded(state, action.meta.arg.id);
        project.comments.error = action.error.message || 'Failed to load comments';
        project.loadingStatus = ELoadingStatus.Failed;
      })
      // Обработка sendComment
      .addCase(sendComment.pending, (state, action) => {
        const project = initializeProjectIfNeeded(state, action.meta.arg.id);
        project.comments.error = '';
      })
      .addCase(sendComment.fulfilled, (state, action) => {
        // При успешной отправке комментария мы перезагружаем комментарии
        // Поэтому здесь ничего не нужно делать
      })
      .addCase(sendComment.rejected, (state, action) => {
        const project = initializeProjectIfNeeded(state, action.meta.arg.id);
        project.comments.error = action.error.message || 'Failed to send comment';
      })
      // Обработка toggleLike
      .addCase(toggleLike.pending, (state, action) => {
        // Ничего не делаем в состоянии при запуске toggleLike
      })
      .addCase(toggleLike.fulfilled, (state, action) => {
        // Ничего не делаем здесь, так как toggleLikeOnProject уже обновляет состояние
      })
      .addCase(toggleLike.rejected, (state, action) => {
        // Ничего не делаем в случае ошибки toggleLike
      })
      // Обработка loadProject
      .addCase(loadProject.pending, (state, action) => {
        const project = initializeProjectIfNeeded(state, action.meta.arg);
        project.loadingStatus = ELoadingStatus.Loading;
        project.error = '';
      })
      .addCase(loadProject.fulfilled, (state, action) => {
        const project = action.payload;
        const existingProject = state[project.id] || {};

        state[project.id] = {
          ...existingProject,
          ...project,
          loadingStatus: ELoadingStatus.Succeeded,
        };
      })
      .addCase(loadProject.rejected, (state, action) => {
        const project = initializeProjectIfNeeded(state, action.meta.arg);
        project.loadingStatus = ELoadingStatus.Failed;
        project.error = action.error.message || 'Failed to load project';
      });
  },
});

export const {
  addItems,
  updateItem,
  deleteItem,
  toggleLikeOnProject,
  setComments,
  addComment,
  setCommentsMeta,
  setLoadingStatus,
  setCommentsError,
  setError,
  reset,
} = slice.actions;

export const selectProject = (id: number) => (state: RootState) => state.projects[id];
export const selectListProject = () => (state: RootState) => state.projects;
export const selectCommentsTotal = (id: number) => (state: RootState) =>
  state.projects[id]?.comments?.total ?? 0;
export const selectComments = (id: number) => (state: RootState) =>
  state.projects[id]?.comments?.items ?? [];
export const selectCommentsError = (id: number) => (state: RootState) =>
  state.projects[id]?.comments?.error;
export const selectLoadingStatus = (id: number) => (state: RootState) =>
  state.projects[id]?.loadingStatus;
export const selectError = (id: number) => (state: RootState) => state.projects[id]?.error;

const projects = {
  ...slice.actions,
  selectProject,
  selectListProject,
  selectComments,
  selectCommentsTotal,
  selectCommentsError,
  selectLoadingStatus,
  selectError,
  loadComments,
  sendComment,
  toggleLike,
  loadProject,
};

export const projectsReducer = slice.reducer;
export default projects;
