import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { IQuizResult } from '@http/models/quiz';
import { ELoadingStatus } from '@http/enums';
import { v1 } from '@http/api/v1';
import { IQuizResultResponse } from '@http/api/v1/quiz/quiz';
import { RootState } from '../store';

export const fetchQuizResult = createAsyncThunk<
  IQuizResultResponse,
  number,
  { rejectValue: string }
>('quiz-result/fetchQuizResult', async (solutionId, { rejectWithValue }) => {
  try {
    const response = await v1.quiz?.result.get(solutionId);
    if (response.errorCode) {
      return rejectWithValue(response.errorMsg!);
    }
    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

interface QuizResultState {
  quizResult: IQuizResult | null;
  loadingStatus: ELoadingStatus;
  error: string | null;
}

const initialState: QuizResultState = {
  quizResult: null,
  loadingStatus: ELoadingStatus.Idle,
  error: null,
};

const slice = createSlice({
  name: 'quiz-result',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuizResult.pending, (state) => {
        state.loadingStatus = ELoadingStatus.Loading;
        state.error = null;
      })
      .addCase(fetchQuizResult.fulfilled, (state, action: PayloadAction<IQuizResult>) => {
        state.loadingStatus = ELoadingStatus.Succeeded;
        state.quizResult = action.payload;
      })
      .addCase(fetchQuizResult.rejected, (state, action) => {
        state.loadingStatus = ELoadingStatus.Failed;
        state.error = action.payload as string;
      });
  },
});

const quizResult = {
  ...slice.actions,
  selectQuizResult: (state: RootState) => state.quizResult?.quizResult,
  selectQuizResultError: (state: RootState) => state.quizResult.error,
  selectQuizResultLoadingStatus: (state: RootState) => state.quizResult.loadingStatus,
};

export const quizResultReducer = slice.reducer;
export default quizResult;
