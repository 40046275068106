import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { batch } from 'react-redux';

import { ELoadingStatus } from '@http/enums';
import { AppThunk, RootState } from '../store';
import { IUserItem } from '@models/user-item';
import { v1 } from '@api/v1';
import { IRequestFollowProps } from '@http/models/request-follow-props';

interface IFollowingState {
  items: IUserItem[];
  scrollPosition: number;
  loadingStatus: ELoadingStatus;
  page: number;
  total: number;
  error?: string;
}

const initialState: IFollowingState = {
  items: [],
  loadingStatus: ELoadingStatus.Idle,
  page: 1,
  scrollPosition: 0,
  total: 0,
};

const slice = createSlice({
  name: 'following',
  initialState,
  reducers: {
    addItems: (state, action: PayloadAction<IUserItem[]>) => {
      state.items = uniqBy([...state.items, ...action.payload], 'id');
    },
    setItems: (state, action: PayloadAction<IUserItem[]>) => {
      state.items = action.payload;
    },
    setScrollPosition: (state, action: PayloadAction<number>) => {
      state.scrollPosition = action.payload;
    },
    setLoadingStatus: (state, action: PayloadAction<ELoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    reset: (state) => {
      state.items = [];
      state.scrollPosition = 0;
      state.loadingStatus = ELoadingStatus.Idle;
      state.page = 1;
      state.total = 0;
    },
  },
});

const { addItems, setItems, setScrollPosition, setLoadingStatus, setTotal, setPage, setError } =
  slice.actions;

const loadData =
  ({ loadmore, search, userId }: IRequestFollowProps): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const page = loadmore ? state.userList.page + 1 : 1;

    try {
      if (!loadmore) {
        dispatch(setLoadingStatus(ELoadingStatus.Loading));
      }

      const response = await v1.user.following.get({
        page,
        userId,
        ...(search !== '' && { search }),
      });

      if (response.errorMsg) {
        batch(() => {
          dispatch(setLoadingStatus(ELoadingStatus.Failed));
          dispatch(setError(response.errorMsg));
        });

        return;
      }

      batch(() => {
        dispatch(loadmore ? addItems(response.items) : setItems(response.items));
        dispatch(setTotal(response.total));
        dispatch(setPage(page));
      });
    } catch (e) {
      batch(() => {
        dispatch(setLoadingStatus(ELoadingStatus.Failed));
        dispatch(setError(e));
      });
    } finally {
      dispatch(setLoadingStatus(ELoadingStatus.Succeeded));
    }
  };

const following = {
  ...slice.actions,
  loadData,
  selectTotal: (state: RootState) => state.following.total,
  selectError: (state: RootState) => state.followers.error,
  selectItems: (state: RootState) => state.following.items,
  selectLoadingStatus: (state: RootState) => state.following.loadingStatus,
  selectScrollPosition: (state: RootState) => state.following.scrollPosition,
};

export const followingReducer = slice.reducer;
export default following;
