import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState, AppThunk } from '../store';
import { ISkillItem } from '@http/models/skill-item';
import { v1 } from '@api/v1';

interface UserSkillListState {
  error?: string;
  loading: boolean;
  items: ISkillItem[];
}

const initialState: { [userId: string]: UserSkillListState } = {};

const createState = (): UserSkillListState => ({
  loading: false,
  items: [],
});

const slice = createSlice({
  name: 'userSkillList',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ userId: string; loading: boolean }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? createState();
      state[action.payload.userId].loading = action.payload.loading;
    },
    setItems: (state, action: PayloadAction<{ userId: string; items: ISkillItem[] }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? createState();
      state[action.payload.userId].items = action.payload.items;
    },
    setError: (state, action: PayloadAction<{ userId: string; error?: string }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? createState();
      state[action.payload.userId].error = action.payload.error;
    },
  },
});

const { setLoading, setItems, setError } = slice.actions;

const userSkillList = {
  setLoading: (userId: string, loading: boolean) => setLoading({ userId, loading }),
  setItems: (userId: string) => (state: RootState) => state.userSkillList[userId]?.items ?? [],
  setError: (userId: string) => (state: RootState) => state.userSkillList[userId]?.error,
  selectLoading: (state: RootState) => state.userSkillList.loading,
  selectItems: (state: RootState) => state.userSkillList.items,
  selectError: (state: RootState) => state.userSkillList.error,
  loadData:
    (userId: string): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setError({ userId, error: undefined }));
        const response = await v1.user.skill.get(userId);
        if (response.errorCode) {
          dispatch(setError({ userId, error: response.errorMsg }));
          console.log(`errorCode: ${response.errorCode}; errorMsg: ${response.errorMsg}`);
          return;
        }
        dispatch(setItems({ userId, items: response.items ?? [] }));
      } finally {
        dispatch(setLoading({ userId, loading: false }));
      }
    },
};

export const userSkillListReducer = slice.reducer;
export default userSkillList;
